<template>
  <div style="margin-bottom: 50px;">
    <v-container>
      <v-select
        v-model="type"
        :items="types"
        @change="blokByType"
        item-text="name_proj"
        item-value="name_proj"
        label="Select Type"
        persistent-hint
        single-line
      ></v-select>

      <v-select
        v-model="selectedItem"
        v-if="type.length > 0"
        :items="blokByType"
        @change="changeValue"
        item-text="name_blok"
        item-value="id"
        label="Select Blok"
        persistent-hint
        single-line
      ></v-select>
      <!-- <v-select
            v-model="selectedItem"
                v-else
            :items="blokByType"
            item-text="name_blok"
            item-value="name_blok"
            label="Select Blok"
            persistent-hint
            single-line aria-selected=""
        ></v-select> -->
      <!-- 
        <v-select v-model="selectedItem" :items="items" label="Select Blok" @change="changeValue">
            <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title :id="attrs['aria-labelledby']" v-text="item">
                </v-list-item-title>
            </v-list-item>
            </template>
        </v-select> -->

      <div class="text-center" style="margin-top: 20px; margin-bottom: 20px;">
        <h6 v-if="stocklist.length > 0">BLOK {{ stocklist[0].name_blok }}</h6>

        <!-- PILIH NOMER KAVLING -->
        <div class="container-fluid">
          <div class="container text-center">
            <button
              v-for="stock in stocklist"
              :key="stock.id"
              v-on:click="pilihKavling(stock.id, stock.kav)"
              type="button"
              class="btn btn-block"
              v-bind:class="{
                available: stock.status == 'AVAILABLE',
                booked: stock.status == 'BOOKED',
                hold: stock.status == 'HOLD',
                selected: stock.kav == selectKavling,
                show_unit: stock.show_unit == 1,
              }"
              :disabled="stock.available == 0 || stock.show_unit == 1"
            >
              {{ stock.kav }}
            </button>
          </div>

          <div class="container px-0">
            <p class="text-left text-muted">Status :</p>
            <div
              class="row d-flex justify-content-center text-center"
              style="color: #9a95ac;"
            >
              <div
                class="col-2 d-flex justify-content-center px-0"
                style="font-size: 10px;"
              >
                <div class="btn-block-clear me-2"></div>
                <div class="pt-1">Available</div>
              </div>
              <div
                class="col-2 d-flex justify-content-center px-0"
                style="font-size: 10px;"
              >
                <div class="btn-block-abu me-2"></div>
                <div class="pt-1">Booked</div>
              </div>
              <div
                class="col-2 d-flex justify-content-center px-0"
                style="font-size: 10px;"
              >
                <div class="btn-block-yellow me-2"></div>
                <div class="pt-1">Hold</div>
              </div>
              <div
                class="col-2 d-flex justify-content-center px-0"
                style="font-size: 10px;"
              >
                <div class="btn-block-blue me-2"></div>
                <div class="pt-1">Selected</div>
              </div>
              <div
                class="col-2 d-flex justify-content-center px-0"
                style="font-size: 10px;"
              >
                <div class="btn-block-orange me-2"></div>
                <div class="pt-1">Show Unit</div>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-success btn-block w-100 mx-0"
            style="
              position: fixed;
              z-index: 1;
              bottom: 0;
              left: 0;
              align: center;
              margin: 0;
            "
            data-bs-toggle="modal"
            data-bs-target="#exampleModalToggle"
          >
            <i class="fas fa-home"></i>
            Confirm Unit
          </button>
        </div>

        <!-- FORM INPUT -->
        <div class="container-fluid" v-for="stock in stocklist" :key="stock.id">
          <div v-if="stock.id == stocklist_id">
            <!-- FORM 1: Pilih Harga -->
            <!-- <div  v-for="stock in stocklist" :key="stock.id">
                    <div v-if="stock.id==stocklist_id" > -->
            <div
              class="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalToggleLabel">
                      Harga Kavling
                      <strong>{{ stock.name_blok }}/{{ stock.kav }}</strong>
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <v-radio-group
                      class="caption justify-start"
                      v-model="pay_method"
                      row
                    >
                      <v-radio label="Cash" value="CASH"></v-radio>
                      <v-radio label="Bertahap" value="BERTAHAP"></v-radio>
                      <v-radio label="KPR" value="KPR"></v-radio>
                    </v-radio-group>

                    <p v-if="pay_method == 'BERTAHAP'" class="text-muted">Pilihan Tahap</p>
                    <v-radio-group
                      class="caption d-flex justify-content-center"
                      style="background-color:rgba(88, 159, 240, 0.17)"
                      v-if="pay_method == 'BERTAHAP'"
                      v-model="jml_tahap"
                      row
                    >
                    
                      <v-radio label="4 Bulan" value="4"></v-radio>
                      <v-radio label="6 Bulan" value="6"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-primary"
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      v-bind:style="
                        pay_method == null ? 'background: #CCC;border:0;' : ''
                      "
                      :disabled="pay_method == null"
                    >
                      <strong>Next</strong>
                      <i class="fas fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
                  </div> -->

            <!-- FORM 2: Upload Data -->
            <!-- <div  v-for="stock in stocklist" :key="stock.id">
                    <div v-if="stock.id==stocklist_id" > -->

            <div
              class="modal fade"
              id="exampleModalToggle2"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel2"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content text-left">
                  <form
                    @submit.prevent="saveBooking"
                    enctype="multipart/form-data"
                  >
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalToggleLabel2">
                        Booking Confirmation
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <table class="table mb-5">
                        <tbody>
                          <tr>
                            <td style="width: 100px;">
                              <strong>Kavling</strong>
                            </td>
                            <td>
                              :
                              <strong>
                                {{ stock.name_blok }}/{{ stock.kav }}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td><strong>LB</strong></td>
                            <td>: {{ stock.lb }}</td>
                          </tr>
                          <tr>
                            <td><strong>LT</strong></td>
                            <td>: {{ stock.lt }}</td>
                          </tr>
                          <tr>
                            <td><strong>Harga</strong></td>
                            <td v-if="pay_method == 'CASH'">
                              : {{ stock.hrg_cash.toLocaleString('id-ID') }}
                            </td>
                            <td v-else-if="pay_method == 'KPR'">
                              : {{ stock.hrg_kpr.toLocaleString('id-ID') }}
                            </td>
                            <td v-else-if="pay_method == 'BERTAHAP'">
                              : {{ stock.hrg_tahap.toLocaleString('id-ID') }}
                            </td>
                            <td v-else>:</td>
                          </tr>
                          <tr>
                            <td><strong>Metode</strong></td>
                            <td>: {{ pay_method }}</td>
                          </tr>
                        </tbody>
                      </table>

                      <!-- <v-select class="mb-0"  :items="namaSumber"  label="Pilih Sumber">
                          <template v-slot:item="{ item, attrs, on }">
                              <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                              </v-list-item>
                          </template>
                      </v-select> -->

                      <v-select class="mb-0"  :items="namaSumber"  label="Pilih Sumber" v-model="sumberdata" required>
                          <template v-slot:item="{ item, attrs, on }">
                              <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                              </v-list-item>
                          </template>
                      </v-select>

                      <!-- <select class="form-select" id="UnitType" v-model="sumberdataid">
                        <option value="">Pilih</option>
                        <option v-for="n in sumberdatas" :key="n.SumberDataID" :value="n.NamaSumber">{{n.NamaSumber}}</option>
                    </select> -->
                      <v-text-field
                        class="mb-5"
                        v-model="full_name"
                        label="Nama Lengkap Sesuai SPR"
                        hide-details="auto"
                        required
                      ></v-text-field>
                      <v-text-field
                        class="mb-5"
                        v-model="phone"
                        label="Phone"
                        type="number"
                        hide-details="auto"
                        required
                      ></v-text-field>

                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">
                          Input KTP
                        </label>
                        <input
                          class="form-control form-control-sm"
                          required
                          accept="application/pdf"
                          @change="getKtp"
                          type="file"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">
                          Input NPWP
                        </label>
                        <input
                          class="form-control form-control-sm"
                          accept="application/pdf"
                          @change="getNpwp"
                          type="file"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">
                          Input BPJS
                        </label>
                        <input
                          class="form-control form-control-sm"
                          accept="application/pdf"
                          @change="getBpjs"
                          type="file"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">
                          Input SPR
                        </label>
                        <input
                          class="form-control form-control-sm"
                          required
                          accept="application/pdf"
                          @change="getSpr"
                          type="file"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="formFileSm" class="form-label">
                          Input Bukti Transfer
                        </label>
                        <input
                          class="form-control form-control-sm"
                          required
                          accept="application/pdf"
                          @change="getTrf"
                          type="file"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="note" class="form-label">
                          Catatan
                        </label>
                        <textarea id="note" class="form-control" v-model="note" cols="10" rows="5"></textarea>
                      </div>
                      <h6 class="mt-4">Real Blokplan</h6>
                      <div class="blokplan">
                        <img
                          v-if="stock.img_blokplan"
                          class="img-fluid w-100"
                          :src="
                            base_url +
                            '/storage/images/blokplan/' +
                            stock.img_blokplan
                          "
                          alt=""
                        />
                        <div v-else class="alert alert-secondary" role="alert">
                          Image not Available
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button
                        type="submit"
                        class="btn btn-success btn-block w-100"
                        style="color: #fff;"
                        data-bs-dismiss="modal"
                      >
                        <i class="fas fa-check-double"></i>
                        Booking
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- </div>
                  </div> -->
          </div>
        </div>
        <!-- END FORM -->
      </div>
    </v-container>
  </div>
</template>

<script>
// import BlockButton from '@/components/BlockButton.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Booking',
  components: {
    // BlockButton,
  },
  data() {
    return {
      modalShow: true,
      types: [],
      type: '',
      bloks: [],
      blok: '',
      namaSumber : [],
      sumberdata : '',
      types_name: [],
      selectKavling: 0,
      kavlings: [],
      stocklist: [],
      stocklist_id: 0,
      pay_method: null,
      jml_tahap: '',
      selectedItem: null,
      note:'',
      isBooked: false,
      isSuccess: false,

      base_url: process.env.VUE_APP_API_URL,

      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          'Avatar size should be less than 2 MB!',
      ],
      requiredRule: [(v) => !!v || 'required field'],

      full_name: '',
      phone: '',
      ktp: null,
      npwp: null,
      spr: null,
      bpjs: null,
      trf: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      prevUrl: 'prevUrl', // <= ini
    }),
    blokByType() {
      let type = this.type
      return this.bloks.filter((blok) => {
        if (blok.name_proj == type) return blok
      })
    },
  },
  methods: {
    ...mapActions({
      setAlert: 'alert/set',
      setAuth: 'auth/set',
    }),
    pilihKavling(id, kav) {
      this.selectKavling = kav
      this.stocklist_id = id
    },
    updateStock() {
      let formAvailability = new formAvailability()
      formAvailability.set('id_stocklist', this.stocklist_id)

      let config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }

      this.axios.post('/availability', formAvailability, config)
    },

    getKtp(e) {
      let ktp = e.target.files[0]
      this.ktp = ktp
    },
    getNpwp(e) {
      let npwp = e.target.files[0]
      this.npwp = npwp
    },
    getSpr(e) {
      let spr = e.target.files[0]
      this.spr = spr
    },
    getBpjs(e) {
      let bpjs = e.target.files[0]
      this.bpjs = bpjs
    },
    getTrf(e) {
      let trf = e.target.files[0]
      this.trf = trf
    },

    saveBooking() {
      let formData = new FormData()
      formData.append('id_sales', this.user.KodeSales)
      formData.append('id_stocklist', this.stocklist_id)
      formData.append('sumberdata', this.sumberdata)
      formData.append('full_name', this.full_name)
      formData.append('phone', this.phone)
      formData.append('ktp', this.ktp)
      formData.append('npwp', this.npwp)
      formData.append('spr', this.spr)
      formData.append('bpjs', this.bpjs)
      formData.append('trf', this.trf)
      formData.append('pay_method', this.pay_method)
      formData.append('jml_tahap', this.jml_tahap)
      formData.append('note', this.note)
      formData.append('book_status', 'BOOKED')

      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type':
            'application/json, multipart/form-data, application/pdf',
          'Authorization': 'Bearer ' + this.user.api_token,
          'X-Requested-With': 'XMLHttpRequest',
        },
      }

      this.axios
        .post('/booking/store', formData, config)
        .then((response) => {
          if(response.status == 'success'){
            console.log(response)
            this.$router.push('/success')
          }else{
              this.setAlert({
                status: true,
                text: response.data.message,
                color: 'danger',
              })
          }
        })
        .catch((error) => {
          let { data } = error
          this.setAlert({
            status: true,
            text: data.message,
            color: 'danger',
          })
        })
    },
    close() {
      this.$emit('closed', false)
    },
    clear() {
      this.$refs.form.reset()
    },

    changeValue() {
      // let formData = new FormData()
      // formData.set('blok', this.selectedItem)

      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json', //must
          'Access-Control-Allow-Methods': 'GET, POST',
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }

      this.axios
        .get('/stocklist?blok=' + this.selectedItem, config)
        .then((response) => {
          let { data } = response.data
          this.stocklist = data
        })
        .catch((error) => {
          let { data } = error.response
          this.setAlert({
            status: true,
            text: data.message,
            color: 'danger',
          })
        })
    },
    changeType() {
      let config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + this.user.api_token,
        },
      }

      this.axios
        .get('/stocklist?type=' + this.selectedItem, config)
        .then((response) => {
            let { data } = response.data
            this.stocklist = data
        })
        .catch((error) => {
          let { data } = error.response
          this.setAlert({
            status: true,
            text: data.message,
            color: 'danger',
          })
        })
    },
  },

  created() {
    if (this.types && this.types.length == 0) {
      let config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.get('/type', config).then((response) => {
        let { data } = response.data
        this.types = data
      })
      .catch(()=>{
          this.setAuth({})
          this.$router.push('/login')
      })

      this.axios.get('/blok', config).then((response) => {
        let { data } = response.data
        this.bloks = data
      })

      this.axios.get('/sumberdata', config).then((response) => {
        for(var i =0; i<15; i++){
          this.namaSumber[i]=response.data[i].NamaSumber
        }
        this.sumberdatas = response.data
      })

    }
  },
}
</script>

<style scoped>
.btn-block {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 11;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  margin: 4px;
  text-align: center;
  padding: 0;
  font-weight: 500;
}

.btn-block-clear,
.btn-block-abu,
.btn-block-yellow,
.btn-block-orange,
.btn-block-blue {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #e4e4e4;
}

.btn-block-clear,
.available {
  background: #4caf50;
}

.btn-block-abu,
.booked {
  background: #c62727;
}
button:disabled,
button[disabled] {
  opacity: unset;
}

.notcompleted {
  background: #ccc;
}
.btn-block-yellow,
.hold {
  background: #fbd45d;
}
.btn-block-blue,
.selected {
  background: #4b95f5;
}

.btn-block-orange,
.show_unit {
  background: #ec681b;
}
</style>
