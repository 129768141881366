<template>
<div class="container mt-5">
  <img src="../assets/opening-booking.png" class="w-100 my-5" alt="">
    <h3>Sign in</h3>
     <!-- <div class="alert alert-warning w-100" role="alert">
              {{info}}
            </div> -->
  <form>
    <v-text-field
      v-model="username"
      :error-messages="usernameErrors"
      :counter="15"
      label="Username"
      required
      @input="$v.username.$touch()"
      @blur="$v.username.$touch()"
    ></v-text-field>
   <v-text-field
            :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'password' : 'text'"
            name="input-10-2"
            label="Password"
            hint="At least 6 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="show2 = !show2"
          ></v-text-field>

    <v-btn
      class="mr-4"
      color="#FB8C2E"
      style="color:#fff"
      @click="submit"
    >
      submit
    </v-btn>
    <v-btn @click="clear" color="#dedede">
      clear
    </v-btn>
  </form>
</div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength} from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'Login',
    mixins: [validationMixin],

    validations: {
      username: { required, maxLength: maxLength(15) },
      password: { required },
    },

    data () {
        return {
      username: '',
      password: '',
       show2: true,
       rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Min 6 characters',
        },
      //select: null,
      //checkbox: false,
    }
    },

    computed: {
      ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl',
      }),

      usernameErrors () {
        const errors = []
        if (!this.$v.username.$dirty) return errors
        !this.$v.username.maxLength && errors.push('Name must be at most 15 characters long')
        !this.$v.username.required && errors.push('Name is required.')
        return errors
      },

    },

    methods: {
      submit () {
        // this.$v.$touch()
        let formData = {
            'username' : this.username,
            'password' : this.password
        }

        let config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + this.user.api_token
            },
        }

        this.axios.post('/login', formData, config)
        .then((response) => {
            this.info = response.data.message
            let { data } = response.data
            this.setAuth(data)
            if(this.user.id>0){
                // this.setAlert({
                //     status : true,
                //     color  : 'success',
                //     text  : data.message,
                // })
                
                this.$router.push('/myprofile')
                
                this.close()
            }
            else{
                this.setAlert({
                    status : true,
                    color  : 'danger',
                    text  : data.message,
                })
            }
        })
        .catch((error) => {
            let { data } = error.response
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
        })
      },
      clear () {
        this.$v.$reset()
        this.username = ''
        this.password = ''
        // this.select = null
        // this.checkbox = false
      },
       ...mapActions({
          setAlert  : 'alert/set',
          setAuth   : 'auth/set',
        }),
    },
  }
</script>