import Vuex from 'vuex'
import Vue from 'vue'
import alert from './store/alert'
import auth from './store/auth'
import dialog from './store/dialog'
import VuexPersistence from 'vuex-persist'

// const vuexPersist = new VuexPersistence({
//     key: 'my-app',
//     storage: localStorage
// })

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
    modules: {
        alert,
        auth,
        dialog,

    },
    plugins: [new VuexPersistence().plugin],
    // plugins: [VuexPersist()],
    state: {
        prevUrl: '',
    },
    mutations: {

        setPrevUrl: (state, value) => {
            state.prevUrl = value
        },
    },
    actions: {

        setPrevUrl: ({ commit }, value) => {
            commit('setPrevUrl', value)
        },
    },
    getters: {

        prevUrl: state => state.prevUrl,
    },


});