<template>
    <div class="text-center" style="color:#234873">
        <div class="text-center align-center" style="width:100%;height:200px;background:#93B4DC; ">
            <!-- <p style="color:#fff; padding-top:70px;margin-bottom:0px;">2 November 2021</p>
            <small style="color:#fff; margin-top:5px;">Grand Nusa Indah</small> -->

        </div>
        <img src="img/other/success_icon.svg" alt="" style="margin-top:-40px">

        <h1 style="margin-top:40px;color:#234873, 100%">THANK YOU!</h1>
        <small>Booked Successfully</small>

        <div style="background: rgba(196, 196, 196, 0.15); margin-top:40px;margin-bottom:50px; padding:3px">Your Booking Number {{booking_number}}</div>

        <v-btn @click="gotoMyBooking()" depressed color="#FDCE54" dark>
      MY BOOKING
    </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
   name:'BookingSuccess',
   data(){
     return{
       booking_number:''
     }
   },
   methods: {
       ...mapActions({
      setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
    }), 
     gotoMyBooking(){
       this.$router.push('/mybooking')
    },
   },
   computed: {
              ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl', 
      }),
   },
    mounted() {

      let config = {
          headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }


         this.axios.get("/last-book",config)
        .then((response) => {
          this.booking_number = response.data.data
        })
        .catch(err => console.log(err));
    }
}
</script>
