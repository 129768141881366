<template>
    <div style="margin-bottom:50px;">
        <!-- <div class="text-center" style="width:100%;height:50px;background:#93B4DC; padding-top:16px">
        <h5 class="align-center" style="color:#fff;">BOOKING DETAILS</h5> 
        </div> -->
    
        <v-container >
            <v-card-title><h6>Booking Number #{{ bookingDetails.booking_number }}</h6></v-card-title>
            <v-card-text>
                <div>
                    Booking date: {{ bookingDetails.created_at }}<br>
                    Booking status: <span class="badge text-light px-3 pt-1" v-bind:class="{ 'bg-danger' : bookingDetails.book_status == 'BOOKED', 'bg-warning' : bookingDetails.book_status == 'HOLD', 'bg-secondary' : bookingDetails.book_status == 'CANCEL', 'bg-primary' : bookingDetails.book_status == 'AKAD'}" >{{ bookingDetails.book_status }}</span> <br>
                    <div v-if="bookingDetails.id_progress > 1">
                        Progress Status : {{bookingDetails.progress_name}} <br>
                        Progress Note : {{bookingDetails.progress_note}} <br>
                        Estimate Ready : {{bookingDetails.estimate_ready}}
                    </div>
                    <div v-if="bookingDetails.id_progress == 1">
                        Progress Status : {{bookingDetails.progress_name}} <br>
                    </div>
                    <div v-if="bookingDetails.id_progress == 0">
                        Progress Status : - <br>
                        Progress Note : - <br>
                        Estimate Ready : -
                    </div>
                    Sales: {{ this.user.name }} 
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <div>
                    <strong>Name:</strong> {{ bookingDetails.full_name }}<br>
                    <strong>Hp:</strong> {{ bookingDetails.phone }}<br>
                    <strong>Type:</strong> {{ bookingDetails.name_proj }}<br>
                    <strong>Blok/kav:</strong> {{ bookingDetails.name_blok }}/{{ bookingDetails.kav }}<br>
                    <strong>LT/LB:</strong> {{ bookingDetails.lt }}/{{ bookingDetails.lb }}<br>
                    <strong>Harga:</strong> <span v-if="bookingDetails.pay_method=='KPR'">{{ bookingDetails.hrg_kpr.toLocaleString('id-ID') }}</span>
                    <span v-if="bookingDetails.pay_method=='CASH'">{{ bookingDetails.hrg_cash.toLocaleString('id-ID') }}</span>
                    <span v-if="bookingDetails.pay_method=='BERTAHAP'">{{ bookingDetails.hrg_tahap.toLocaleString('id-ID') }}</span><br>
                    <strong>Metode:</strong> {{ bookingDetails.pay_method }}<br>
                </div>
            </v-card-text>
            <router-link to="/mybooking" style="text-decoration:none;">
             <v-btn block
            class="white--text"
      
            color="#93B4DC"
          style="margin-top:20px;"
            >
            CLOSE
            
            </v-btn>
            </router-link>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                bookingDetails:{},
                
            }
        },
        computed: {
            ...mapGetters({
                user  : 'auth/user',
                prevUrl : 'prevUrl', 
            }),
        },
        methods:{
            ...mapActions({
                setAlert  : 'alert/set',
                setAuth   : 'auth/set',
            }),
        },
        mounted() {
        let config = {
          headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

         this.axios.get("/booking/data?id=" + this.$route.params.id,config)
        .then((response) => {
            console.log(response)
          this.bookingDetails = response.data.data
      
      

        })
        // .catch(err => console.log(err));
    }
    }
</script>