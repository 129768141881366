<template>
    <div style="margin-bottom:50px;">
        
        <v-container>
            <h6  style="color:#FB8C2E;" class="mt-2 mb-5">UBAH PASSWORD</h6>
            <!-- <v-divider></v-divider> -->
            <v-form
                ref="form"
                lazy-validation
            >
            <v-text-field
            :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'password' : 'text'"
            name="input-10-2"
            label="New Password"
            hint="At least 6 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="show2 = !show2"
          ></v-text-field>
                  

                <v-btn
                dark block
                color="#FB8C2E"
                class="mr-4 mt-2"
                @click="submit"
                >
                SIMPAN
                </v-btn>
            </v-form>
        </v-container>
        
    </div>
    
</template>

<script>
import {  mapGetters, mapActions } from 'vuex'
export default {
 name:'Profile',
 data: () => ({
      password: '',
       show2: true,
       rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Min 6 characters',
        },
      
    }),
    methods:{
     ...mapActions({
          setAlert  : 'alert/set',
          setAuth   : 'auth/set',
        }),
      submit(){
       let formData = new FormData()
       formData.append('new_password', this.password)

       let config = {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.user.api_token,
          },
       }
       this.axios.post('/change-password',formData,config)
      .then((response) => {
          this.setAlert({
              status : true,
              color  : 'success',
              text  : response.data.message,
          })
          this.setAuth({})
          this.$router.push('/login')
      })
      }
    },
    computed: {
       ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl', 
      }),
    },
    mounted(){
     window.scrollTo(0,0)
   },
}
</script>
