<template>
  <v-app>

    <v-app-bar
      color="blue accent-2"
      dark app v-if="!guest">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ this.$route.meta.title }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
    <v-img src="https://api-booking.makutapro.id/storage/images/logo/logo-kalindo.jpg" class="w-100"></v-img>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="blue accent-1--text text--accent-4"
        >
          <v-list-item @click="gotoMyProfile()">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>

          <v-list-item @click="gotoMyBooking()">
            <v-list-item-icon>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Booking</v-list-item-title>
          </v-list-item>
          

          <v-list-item @click="gotoBookUnit()">
            <v-list-item-icon>
              <v-icon>mdi-floor-plan</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Booking Unit</v-list-item-title>
          </v-list-item>

          <v-list-item @click="gotoSetting()">
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>setting</v-list-item-title>
          </v-list-item>

          <v-list-item  @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  

    <v-main>
      <router-view/>
       <Alert /> 
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
        Alert: () => import( /* webpackChunkName: "alert" */'@/components/Alert.vue'),
  },

  data: () => ({
    drawer: false,
      group: null,
  }),
  methods: {
    ...mapActions({
      setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
    }), 
    gotoMyProfile(){
       this.$router.push('/')
    },
    gotoMyBooking(){
       this.$router.push('/mybooking')
    },
    gotoBookUnit(){
       this.$router.push('/booking')
    },
    gotoSetting(){
       this.$router.push('/setting')
    },
    logout(){
      let config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'Access-Control-Allow-Methods': 'GET, POST',
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.post('/logout', {}, config)
        .then(() => {
          this.setAuth({}) // kosongkan auth ketika logout
          this.setAlert({
              status : true,
              color  : 'success',
              text  : 'Logout successfully',
            })
            this.$router.push('/login')
        })
        .catch(() => {
          // let {data} = error.response  
          this.setAuth({}) // kosongkan auth ketika logout 
          // this.setAlert({
          //     status : true,
          //     color  : 'error',
          //     text  : data.message,
          // })
          this.$router.push('/login')
        })
    }
  },
  computed: {

    isHome () {
      return (this.$route.path==='/')
    },
    ...mapGetters({

      guest : 'auth/guest',
      user : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }), 
    dialog:{
      get () {
        return this.dialogStatus
      },
      set (value) {
        this.setDialogStatus(value)
      }
    }
  },
  mounted() {
    // if(user){
      let config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }

      this.axios.get('/token', config)
       .then((response) => {
         this.token = response
            console.log(response)
            // console.log(user)
        })
        .catch((error) => {
          console.log(error)
          this.setAuth({})
          this.$router.push('/login')
        })
    // }
    
  }
};
</script>
