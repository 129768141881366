<template>
    <div style="margin-bottom:50px;">
        <!-- <div class="text-center d-flex justify-content-between px-3" style="width:100%;height:50px;background:#93B4DC; padding-top:16px">
            <span></span>
            <h5 class="align-center" style="color:#fff;">MY BOOKING</h5>
            <button type="button" class="pb-2" @click="logout()"><i class="fas fa-sign-out-alt" style="color:#fff"></i></button>
        </div> -->
        <v-container >
            <v-row>
                <v-col>
    
                     <v-select
                        v-model="type"
                        :items="types"
                        @change="blokByType"
                        item-text="name_proj"
                        item-value="name_proj"
                        label="Select Type"
                        persistent-hint
                        single-line
                    ></v-select>

                </v-col>
                <v-col>

                     <v-select
                        v-model="blok"
                         v-if="type.length>0"
                        :items="blokByType"
                        item-text="name_blok"
                        item-value="name_blok"
                        label="Select Blok"
                        persistent-hint
                        single-line
                    ></v-select>
                     <v-select
                        v-model="blok"
                         v-else
                        :items="blokByType"
                        item-text="name_blok"
                        item-value="name_blok"
                        label="Select Blok"
                        persistent-hint
                        single-line aria-selected=""
                    ></v-select>

                </v-col>
            </v-row>
            
            <v-btn block class="white--text" :loading="loading3"
            :disabled="loading3" color="primary" @click="goFilter" >
            FILTER
            
            </v-btn>

            </v-container>




            <v-simple-table style="margin-top:20px;">
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left caption">CODE</th>
                        <th class="text-left caption">TYPE/BLOK/KAV</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>

                <tbody v-if="booking.length>0">    
                    <tr class="rounded-lg "  v-for="book in booking" :key="book.id" :class="{ 'booking': book.book_status == 'BOOKED', 'cancel': book.book_status == 'CANCEL', 'hold': book.book_status == 'HOLD', 'akad': book.book_status == 'AKAD' }">
                        <td class="caption" >{{ book.booking_number }}</td>
                        <td class="caption">{{ book.name_proj }}/{{ book.name_blok }}/{{ book.kav }}</td>
                        <td>
                            <router-link :to="'booking/details/'+book.id">
                                <img src="img/other/next_icon.svg" alt="">
                            </router-link>
                        </td>
                    </tr>    
                </tbody>
                <tbody v-else>    
                    <tr>
                        <td colspan="3" class="text-center"> 
                            <div class="alert alert-secondary mt-3" role="alert">
                            Data not available
                            </div>
                        </td>
                       
                    </tr>    
                </tbody>
                </template>
            </v-simple-table>
                        
            <v-card-text style="height: 50px; position: absolute; bottom:0; ">
            <v-fab-transition>
                <router-link to="booking">
                <v-btn color="green"
                    dark
                    absolute
                    top
                    right
                    fab
                >
                <v-icon>mdi-plus</v-icon>
                </v-btn>
                </router-link>
            </v-fab-transition>
          </v-card-text>

    </div>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  export default {
      name:'Home',
    data () {
      return {
        modePrice: null,
        booking:[],
        listType: [],
        loader: null,
        loading3: false,
        types:[],
        type:'',
        bloks:[],
        blok:'',
 

       
        
      }
    },
     watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
    computed: {
              ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl', 
      }),
      blokByType(){
          let type = this.type
          return this.bloks.filter((blok) => {           
            if (blok.name_proj==type) return blok
        })


      }
    },
    methods:{
        ...mapActions({
        //   setAlert  : 'alert/set',
          setAuth   : 'auth/set',
        }),
        goDetail(){
            this.$router.push('/')
        },

        goFilter(){

             let config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
                }
            
            this.axios.get('/booking/data?username=' + this.user.username + '&type='+this.type+'&blok='+this.blok, config)
            .then((response) => {
                let {data } = response.data
                this.booking = data
            })
            .catch((error) => {
                let {data} = error.response
                this.setAlert({
                    status : true,
                    text  : data.message,
                    color  : 'danger',
            }) })
        },
        logout(){
      let config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'Access-Control-Allow-Methods': 'GET, POST',
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.post('/logout', {}, config)
        .then(() => {
          this.setAuth({}) // kosongkan auth ketika logout
          this.setAlert({
              status : true,
              color  : 'success',
              text  : 'Logout successfully',
            })
          this.$router.push( { path: '/' })
        })
        .catch((error) => {
          let {data} = error.response   
          this.setAlert({
              status : true,
              color  : 'error',
              text  : data.message,
          })
        })
    }
        
    },
    created() {
        if(this.types && this.types.length==0){
            let config = {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.user.api_token,
                },
                }
            this.axios.get('/type',config)
            .then((response) => {
                let {data } = response.data
                this.types = data
            })
            .catch(()=>{
                this.setAuth({})
                this.$router.push('/login')
            })

            this.axios.get('/blok',config)
            .then((response) => {
                let {data } = response.data
                this.bloks = data
            })
         }
         

    },
    mounted() {
        
        window.scrollTo(0,0)


        let config = {
          headers: {
            // 'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
      
      this.axios.get('/booking/data?username=' + this.user.username, config)
       .then((response) => {
           
          let {data } = response.data
          this.booking = data
        })

    },

  }
</script>
<style scoped>
    .booking {
        background: rgba(241, 48, 48, 0.17);
    }
    .cancel {
        background: rgba(146, 145, 145, 0.17);
    }
    .hold {
        background: rgba(243, 206, 42, 0.17);
    }
    .akad{
        background: rgba(88, 159, 240, 0.17);
    }
</style>