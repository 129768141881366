import Vue from 'vue'
import Router from 'vue-router'
// import Home from '../views/Home.vue'
import store from '../store'

import MyBooking from '../views/MyBooking.vue'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import Booking from '../views/Booking.vue'
import BookingSuccess from '../views/BookingSuccess.vue'
import BookingDetails from '../views/BookingDetails.vue'
import Setting from '../views/Setting.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,

    routes: [

        {
            path: '/',
            name: 'Profile',
            component: Profile,
            meta: {
                auth: true,
                title: 'My Profile'
            }
        },
        {
            path: '/mybooking',
            name: 'MyBooking',
            component: MyBooking,
            meta: {
                auth: true,
                title: 'My Booking'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,

        },
        {
            path: '/about',
            name: 'About',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "about" */ '../views/About.vue'),
            meta: { auth: true }
        },
        {
            path: '/booking',
            name: 'Booking',
            component: Booking,
            meta: {
                auth: true,
                title: 'Booking Unit'
            }
        },
        {
            path: '/success',
            name: 'BookingSuccess',
            component: BookingSuccess,
            meta: { auth: true }
        },
        {
            path: '/setting',
            name: 'Setting',
            component: Setting,
            meta: { auth: true }
        },
        {
            path: '/booking/details/:id',
            props: true,
            name: 'BookingDetails',
            component: BookingDetails,
            meta: {
                auth: true,
                title: 'Booking Details'
            }
        },
        { path: '*', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        // jika user adalah guest
        if (store.getters['auth/guest']) {
            // tampilkan pesan bahwa harus login dulu
            store.dispatch('alert/set', {
                status: true,
                text: 'Login first',
                color: 'error',
            })
            next('/login')
        } else {
            next()

        }

    } else {
        next()
    }
})


export default router