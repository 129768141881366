<template>
    <div style="margin-bottom:50px;">
        <v-container style="padding-bottom:10px; padding-top:70px;" > 
            <v-row>
                <v-col class="align-self-center"> 
                    <v-row>
                        <v-col class="text-center">
                            <router-link to="photo_profil">
                                <v-avatar size="130" class="mb-3">
                                    <v-img v-if="user.logo" :src="'https://api-booking.makutapro.id/storage/images/logo/'+user.logo"></v-img>
                                    <v-img v-else src="../assets/user.png"></v-img>
                                </v-avatar>
                            </router-link>
                            <br><h4 class="mt-2" >{{user.name}}</h4>{{user.project}}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        
        <v-container>
      <v-row>
        <v-col
          cols="4"
          md="4"
        >
          <v-item >
            <v-card
              color="#F35F5F"
              class="text-center "
              height="100"
            >
            <div class="py-4" style="color:#FFF"><h6 class="mt-2">BOOKED</h6><h3>{{booked}}</h3></div>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="4"
          md="4"
        >
          <v-item >
            <v-card
              color="#FDCE54"
              class="text-center"
              height="100"
            >
            <div class="p-4" style="color:#FFF"><h6>HOLD</h6><h3>{{hold}}</h3></div>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="4"
          md="4"
        >
          <v-item >
            <v-card
              color="#549dfd"
              class="text-center"
              height="100"
            >
            <div class="p-4" style="color:#FFF"><h6>AKAD</h6><h3>{{akad}}</h3></div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
    </div>
    
</template>

<script>
import {  mapActions, mapGetters } from 'vuex'
export default {
 name:'Profile',
 data(){
  return{
    booked : '',
    hold : '',
    akad : ''
  }
 },
  watch: {
   loader () {
     const l = this.loader
     this[l] = !this[l]

     setTimeout(() => (this[l] = false), 3000)

     this.loader = null
   },
 },
 mounted(){
   window.scrollTo(0,0)
 },
    computed: {
              ...mapGetters({
        user  : 'auth/user',
        prevUrl : 'prevUrl', 
      }),
    },
    methods: {
      ...mapActions({
        //   setAlert  : 'alert/set',
          setAuth   : 'auth/set',
        }),
    },
    created() {
      let config = {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.user.api_token,
          },
          }
      this.axios.get('/count-booked',config)
      .then((response) => {
          let {data } = response.data
          this.booked = data
      })
      .catch(()=>{
          this.setAuth({})
          this.$router.push('/login')
      })

      this.axios.get('/count-hold',config)
      .then((response) => {
          let {data } = response.data
          this.hold = data
      })

      this.axios.get('/count-akad',config)
      .then((response) => {
          let {data } = response.data
          this.akad = data
      })
    },
}
</script>

<style scoped>
    .booking {
        background: rgba(139, 187, 222, 0.17);
    }
    .cancel {
        background: rgba(247, 7, 7, 0.17);
    }
    .pending {
        background: rgba(243, 206, 42, 0.17);
    }
</style>